import Layout from '../components/layout';

function AboutPage() {
  // const authorImages = data.

  return (
    <Layout pageSEO={{ title: 'About' }}>
      <h1>Authors</h1>
    </Layout>
  )
}

export default AboutPage

// export const query = graphql`
//   query {
//   }
// `
